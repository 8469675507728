
import {
  modalController,
  popoverController,
  loadingController,
} from "@ionic/vue";
import { defineComponent } from "vue";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { useRouter } from "vue-router";
import { getInstance } from "@/services/project/projectService";
import { TaskService } from "@/services/task/TaskService";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
export default defineComponent({
  name: "TaskProject",
  components: {
    Header,
    SearchBar,
    TaskCards,
    ProjectCss,
  },
  data() {
    const projectService = getInstance();
    const params = this.$route.params;
    return {
      list: new Array(0),
      taskService: new TaskService(),
      projectService: projectService,
      projectParams: {
        limit: 10,
        offset: 1,
        projectName: "",
        partakeType: "myCreate",
      },
      taskParams: {
        limit: 10,
        offset: 1,
        name: "",
        creatorId: SystemUtils.loginUser.id,
      },
      isMore: true,
      router: useRouter(),
      params: params,
      pageType: "",
      headerTitle: "",
      operabilityCard: false,
      islist: false,
    };
  },
  created() {
    this.initData();
  },

  methods: {
    initData() {
      this.islist = true;
      this.getParams();
    },
    getParams() {
      if (this.params.type) {
        this.pageType = this.params.type.toString();
        switch (this.pageType) {
          case "0":
            this.headerTitle = "我的项目";
            this.requsetProjectData(this.projectParams, null);
            break;
          case "1":
            this.headerTitle = "我的任务";
            this.requsetTaskData(this.taskParams, null);
            break;
        }
      }
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true; 
      this.projectParams.offset = 1;
      this.taskParams.offset = 1;
      this.taskParams.name = "";
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        switch (this.pageType) {
          case "0":
            this.projectParams.offset++;
            this.requsetProjectData(this.projectParams, event);
            break;
          case "1":
            this.taskParams.offset++;
            this.requsetTaskData(this.taskParams, event);
            break;
        }
      }
    },
    //执行搜索
    searchHandler(val: any) {
      if (Utils.isInCludeEmoji(val.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.isMore = true;
      this.projectParams.offset = 1;
      if (this.pageType == "0") {
        this.projectParams.projectName = val.searchKey;
        this.requsetProjectData(this.projectParams, null);
      } else if (this.pageType == "1") {
        this.taskParams.name = val.searchKey;
        this.requsetTaskData(this.taskParams, null);
      } else if (this.pageType == "2") {
        this.projectParams.projectName = val.searchKey;
        this.requsetProjectData(this.projectParams, null);
      }
    },
    //我的项目接口
    requsetProjectData(pamas: any, event: any) {
      this.projectService.getProjectList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data; 
            if (result == null || result.length <= 0) {
              Utils.presentToastWarning("暂无数据，请换个搜索条件吧！");
              event.target.disabled = true;
            } else {
              if (this.pageType == "2") {
              for (let index = 0; index < result.length; index++) {
                Object.assign(result[index], { checked: false });
              }
            }
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }

            this.islist = false;
          } else {
            if (event != null) {
              // this.isEmpty = true;
              this.list = [];
            }
            this.islist = false;
          }
        },
        (e: any) => {}
      );
    },
    //我的任务接口
    requsetTaskData(pamas: any, event: any) {
      this.taskService.getTaskList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r;
            if (result == null || result.length <= 0) {
              Utils.presentToastWarning("暂无数据，请换个搜索条件吧！");
              if (event) {
                event.target.disabled = true;
              }
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
            this.islist = false;
          } else {
            if (event != null) {
              // this.isEmpty = true;
              this.list = [];
            }
            this.islist = false;
          }
        },
        (e: any) => {}
      );
    },
    //跳转详情
    itemClick(type: string, item: any) {
      if (type == "project") {
        this.router.push({
          path: "/ProjectPage",
          query: {
            projectId: item.id,
            projectName: item.name,
            isStart: item.isStart,
          },
        });
      } else if (type == "task") {
        this.router.push({
          path: "/task-page",
          query: {
            id: item.id,
          },
        });
      }
    },
  },
});
